<template>
    <layout-div>
        <div class="content-container">
            <NavBarComponent @logout="logoutAction" />
            <div class="greeting-text">
                <p>Hier kannst du Abwesenheiten melden...</p>
            </div>
        </div>

        <br>

        <div class="flex justify-center mt-5">
            <button class="button" @click="showAbsenceModal = true">
                Abwesenheit melden
            </button>
        </div>

        <div v-if="absencesChildren.length > 0" class="mt-10 px-4">
            <h2 class="text-xl font-bold mb-4">Betreuungs-Kind Abwesenheit:</h2>
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white rounded-lg shadow-lg w-full">
                    <tbody>
                        <tr v-for="(absence, index) in absencesChildren" :key="index" class="border-b hover:bg-gray-100">
                            <td class="py-2 px-4">{{ getChildName(absence.fk_children_id) }}</td>
                            <td class="py-2 px-4">{{ formatDate(absence.absence_start) }}</td>
                            <td class="py-2 px-4">{{ absence.comment }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="userAbsences.length > 0" class="mt-10 px-4">
            <h2 class="text-xl font-bold mb-4">Meine Abwesenheiten:</h2>
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white rounded-lg shadow-lg w-full">
                    <tbody>
                        <tr v-for="(absence, index) in userAbsences" :key="index" class="border-b hover:bg-gray-100">
                            <td class="py-2 px-4"><b>ID: {{ absence.id }}</b></td>
                            <td v-if="(absence.sickConfirmationFileId === null || absence.healthConfirmationFileId === null)" class="py-2 px-4">
                                <button class="bg-[#0B615E] hover:bg-[#3f4f45] text-white font-bold py-2 px-4 rounded transition-colors duration-300" @click="openUploadModal(absence)">
                                    <i class="bi bi-upload h-6 w-6"></i> Hochladen
                                </button>
                            </td>
                            <td class="py-2 px-4">
                                <span v-if="absence.sickConfirmationFileId !== null && absence.sickConfirmationFileId !== undefined" class="inline-block bg-green-500 text-white px-2 py-1 rounded-full text-sm mb-1">
                                    Abwesend.Doc <i class="bi bi-check-circle"></i>
                                </span>
                                <span v-if="absence.healthConfirmationFileId !== null && absence.healthConfirmationFileId !== undefined" class="inline-block bg-green-500 text-white px-2 py-1 rounded-full text-sm mb-1">
                                    Anwesend.Doc <i class="bi bi-check-circle"></i>
                                </span>
                                <span v-if="absence.sickConfirmationFileId === null && absence.healthConfirmationFileId === null" class="inline-block bg-red-500 text-white px-2 py-1 rounded-full text-sm">
                                    Bestätigungen Fehlen
                                </span>
                            </td>
                            <td class="py-2 px-4">{{ absence.absence_type }}</td>
                            <td class="py-2 px-4">{{ formatDate(absence.absence_start) }} - {{absence.absence_time_start}} </td>
                            <td class="py-2 px-4">{{ formatDate(absence.absence_end) }} - {{absence.absence_time_end}}</td>                          
                            <td class="py-2 px-4">{{ absence.comment }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div><br><br><br>

        <div v-if="showAbsenceModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div class="bg-white rounded-lg shadow-xl w-96 max-h-screen overflow-y-auto">
                <div class="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h5 class="text-lg font-bold">Abwesenheit melden</h5>
                    <button @click="showAbsenceModal = false" class="text-gray-600 hover:text-gray-900 text-xl font-bold">&times;</button>
                </div>
                <div class="p-6">
                    <div class="mb-4">
                        <label for="absenceType" class="block text-sm font-medium text-gray-700">Wer ist abwesend?</label>
                        <select id="absenceType" v-model="absenceType" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option value="">Bitte wählen</option>
                            <option value="caregiver">Mich Abwesend melden</option>
                            <option value="child">Kind Abwesend</option>
                        </select>
                    </div>

                    <div class="mb-4" v-if="absenceType === 'child'">
                        <label for="childSelect" class="block text-sm font-medium text-gray-700">Kind auswählen</label>
                        <select id="childSelect" v-model="selectedChild" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option value="">Bitte Kind auswählen</option>
                            <option v-for="(child, index) in children" :key="index" :value="child">{{ child }}</option>
                        </select>
                    </div>

                    <div class="mb-4">
                        <label for="absenceDateStart" class="block text-sm font-medium text-gray-700">Abwesenheit-Start</label>
                        <input type="date" v-model="absenceDateStart" id="absenceDateStart" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                    </div>

                    <div class="mb-4">
                        <label for="absenceDateEnd" class="block text-sm font-medium text-gray-700">Abwesenheit-Ende</label>
                        <input type="date" v-model="absenceDateEnd" id="absenceDateEnd" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                    </div>

                    <div class="mb-4">
                        <label for="startTime" class="block text-sm font-medium text-gray-700">Uhrzeit-Start</label>
                        <select id="startTime" v-model="startTime" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option v-for="time in timeOptions" :key="time" :value="time">{{ time }}</option>
                        </select>
                    </div>

                    <div class="mb-4">
                        <label for="endTime" class="block text-sm font-medium text-gray-700">Uhrzeit-Ende</label>
                        <select id="endTime" v-model="endTime" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option v-for="time in timeOptions" :key="time" :value="time">{{ time }}</option>
                        </select>
                    </div>

                    <div class="mb-4" v-if="absenceType !== 'child'">
                        <label for="absenceReason" class="block text-sm font-medium text-gray-700">Grund der Abwesenheit</label>
                        <select id="absenceReason" v-model="absenceReason" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                            <option value="Krankenstand">Krankenstand</option>
                            <option value="Pflegeurlaub">Pflegeurlaub</option>
                            <option value="Arzttermin">Arzttermin</option>
                            <option value="Sonderurlaub">Sonderurlaub</option>
                            <option value="Zeitausgleich">Zeitausgleich</option>
                            <option value="Urlaub">Urlaub</option>
                        </select>
                    </div>

                    <div class="mb-4">
                        <label for="comment" class="block text-sm font-medium text-gray-700">Kommentar</label>
                        <textarea id="comment" v-model="comment" rows="3" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"></textarea>
                    </div>
                </div>
                <div class="px-6 py-3 border-t border-gray-200 flex justify-end">
                    <button class="button mr-2" @click="showAbsenceModal = false">
                        Schließen
                    </button>
                    <button class="button" @click="reportAbsence" :disabled="!isValidForm">
                        Abwesenheit melden
                    </button>
                </div>
            </div>
        </div>


        <div v-if="showUploadModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div class="bg-white rounded-lg shadow-xl w-96 max-h-screen overflow-y-auto">
                <div class="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h5 class="text-lg font-bold">Bestätigung Hochladen</h5>
                    <button @click="closeUploadModal" class="text-gray-600 hover:text-gray-900 text-xl font-bold">&times;</button>
                </div>
                <div class="p-6">
                    <div class="mb-4">
                        <label for="documentType" class="block text-sm font-medium text-gray-700">Dokumenttyp</label>
                        <select id="documentType" v-model="selectedDocumentType" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" :disabled="selectedAbsence && (selectedAbsence.healthConfirmationFileId !== null || (selectedAbsence.sickConfirmationFileId !== null && selectedAbsence.healthConfirmationFileId !== null))" @change="validateSelection">
                            <option value="">Bitte auswählen</option>
                            <option v-if="selectedAbsence && selectedAbsence.sickConfirmationFileId === null && selectedAbsence.healthConfirmationFileId === null" value="CAREGIVER_SICK_DOC">Abwesenheitsbestätigung</option>
                            <option v-if="selectedAbsence && selectedAbsence.sickConfirmationFileId !== null && selectedAbsence.healthConfirmationFileId === null" value="CAREGIVER_HEALTH_DOC">Rückkehrbestätigung</option>
                        </select>
                    </div>

                    <input type="file" ref="fileInput" id="fileUpload" @change="handleFileChange" class="hidden" :disabled="selectedAbsence && (selectedAbsence.healthConfirmationFileId !== null || (selectedAbsence.sickConfirmationFileId !== null && selectedAbsence.healthConfirmationFileId !== null))" />

                    <div @drop.prevent="handleFileDrop" @dragover.prevent @click="onDropZoneClick" class="mt-4 p-4 border-dashed border-2 rounded-md text-center cursor-pointer" :class="{'cursor-not-allowed opacity-50': selectedAbsence && (selectedAbsence.healthConfirmationFileId !== null || (selectedAbsence.sickConfirmationFileId !== null && selectedAbsence.healthConfirmationFileId !== null))}" :style="selectedAbsence && (selectedAbsence.healthConfirmationFileId !== null || (selectedAbsence.sickConfirmationFileId !== null && selectedAbsence.healthConfirmationFileId !== null)) ? 'pointer-events: none;' : ''">
                        Klicken Sie hier oder ziehen Sie Ihre Datei zum Hochladen
                    </div>
                </div>
                <div class="px-6 py-3 border-t border-gray-200 flex justify-end">
                    <button class="button mr-2" @click="closeUploadModal">Schließen</button>
                </div>
            </div>
        </div>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NavBarComponent from '../NavBarComponent.vue';

export default {
    name: 'AbsencePage',
    components: {
        LayoutDiv,
        NavBarComponent
    },
    data() {
        return {
            user: {},
            absencesChildren: [],
            absencesCaregiver: [],
            userAbsences: [],
            showAbsenceModal: false,
            showUploadModal: false,
            comment: '',
            absenceType: '',
            selectedChild: '',
            absenceReason: '',
            selectedAbsence: null,
            selectedDocumentType: '',
            file: null,
            children: [],
            errorMessage: '',
            absenceDateStart: '',
            absenceDateEnd: '',
            startTime: '08:00:00',
            endTime: '12:00:00',
            timeOptions: this.generateTimeOptions(),
        };
    },
    watch: {
        absenceType(newType) {
            if (newType === 'child') {
                this.absenceReason = '';
            }
        }
    },
    computed: {
        isValidForm() {
            const isDateValid = this.absenceDateStart && this.absenceDateEnd && (this.absenceDateStart <= this.absenceDateEnd);
            const isTimeValid = this.startTime && this.endTime && (this.startTime <= this.endTime);
            
            return isDateValid && isTimeValid;
        }
    },
    created() {
        this.initializeDashboard();
    },
    methods: {
        generateTimeOptions() {
            const times = [];
            let start = new Date();
            start.setHours(7, 0, 0); // Start bei 07:00:00
            let end = new Date();
            end.setHours(24, 0, 0); // Endet bei 24:00:00

            while (start <= end) {
                times.push(this.formatTime(start)); // Zeit im Format 'HH:mm:ss' hinzufügen
                start.setMinutes(start.getMinutes() + 15); // Um 15 Minuten erhöhen
            }

            return times;
        },
        formatTime(date) {
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        },
        async initializeDashboard() {
            try {
                await this.getUserData();
            } catch (error) {
                console.error("Error during initialization:", error);
            }
        },
        async getUserData() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;

                const today = new Date();
                const formattedDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);

                const userDataResponse = await axios.get(`/users/get_user_data/${this.userId}/${formattedDate}`, { withCredentials: true });

                if (userDataResponse && userDataResponse.data) {
                    this.user = userDataResponse.data.user || {};
                    this.combinedData = userDataResponse.data.combinedData || [];
                    this.absencesChildren = userDataResponse.data.absences_children || [];
                    this.absencesCaregiver = userDataResponse.data.absences_caregiver || [];
                    this.userAbsences = userDataResponse.data.user_absences || [];
                    this.children = this.combinedData.map(item => item.children_name);
                } else {
                    console.error('Unexpected response format:', userDataResponse);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                this.$router.push('/');
            }
        },
        getChildName(id) {
            const child = this.combinedData.find(item => item.fk_children_id === id);
            return child ? child.children_name : 'Unbekannt';
        },
        formatDate(dateStr) {
            return dateStr.slice(6, 8) + '.' + dateStr.slice(4, 6) + '.' + dateStr.slice(0, 4);
        },
        async reportAbsence() {
            try {
                if (!this.isValidForm) {
                    if (!this.absenceDateStart || !this.absenceDateEnd || this.absenceDateStart > this.absenceDateEnd) {
                        this.errorMessage = 'Das Startdatum darf nicht größer als das Enddatum sein.';
                    } else if (this.startTime > this.endTime) {
                        this.errorMessage = 'Die Startzeit darf nicht größer als die Endzeit sein.';
                    }
                    alert(this.errorMessage);
                    return;
                }

                const formattedStartDate = this.absenceDateStart.replace(/-/g, '');
                const formattedEndDate = this.absenceDateEnd.replace(/-/g, '');

                const payload = {
                    force_approve: false,
                    absence_start: formattedStartDate,
                    absence_end: formattedEndDate,
                    absence_time_start: this.startTime,
                    absence_time_end: this.endTime,
                    absence_type: this.absenceReason || 'Abwesend',
                    type: this.absenceType === 'child' ? 'CHILDREN' : 'CAREGIVER',
                    comment: this.comment
                };

                let id;
                if (this.absenceType === 'child') {
                    const childRecord = this.combinedData.find(item => item.children_name === this.selectedChild);
                    if (childRecord && childRecord.fk_children_id) {
                        id = childRecord.fk_children_id;
                    } else {
                        console.error('Kein passender Kind-Datensatz gefunden.');
                        return;
                    }
                } else if (this.absenceType === 'caregiver') {
                    id = this.userId;
                }

                await axios.put(`/users/insert_absence/${id}`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                this.showAbsenceModal = false;
                await this.initializeDashboard();

                alert('Abwesenheit erfolgreich gemeldet!');

            } catch (error) {
                console.error('Fehler beim Melden der Abwesenheit:', error);
                alert('Fehler beim Melden der Abwesenheit. Bitte versuchen Sie es erneut.');
            }
        },
        async logoutAction() {
            try {
                await axios.post('/users/logout', {}, { withCredentials: true });
                this.$router.push('/login');
            } catch (error) {
                console.error('Error during logout:', error);
            }
        },
        openUploadModal(absence) {
            this.selectedAbsence = absence;
            this.showUploadModal = true;

            if (absence.healthConfirmationFileId !== null && absence.sickConfirmationFileId !== null) {
                this.selectedDocumentType = '';
            } else if (absence.sickConfirmationFileId !== null && absence.healthConfirmationFileId === null) {
                this.selectedDocumentType = 'CAREGIVER_HEALTH_DOC';
            } else if (absence.sickConfirmationFileId === null && absence.healthConfirmationFileId === null) {
                this.selectedDocumentType = 'CAREGIVER_SICK_DOC';
            } else {
                this.selectedDocumentType = '';
            }
        },
        closeUploadModal() {
            this.showUploadModal = false;
            this.selectedDocumentType = '';
            this.file = null;
        },
        validateSelection() {
            if (this.selectedAbsence) {
                if (this.selectedAbsence.healthConfirmationFileId !== null || 
                    (this.selectedAbsence.sickConfirmationFileId !== null && this.selectedAbsence.healthConfirmationFileId !== null)) {
                    this.selectedDocumentType = '';
                } else if (this.selectedAbsence.sickConfirmationFileId !== null) {
                    this.selectedDocumentType = 'CAREGIVER_HEALTH_DOC';
                } else {
                    this.selectedDocumentType = 'CAREGIVER_SICK_DOC';
                }
            }
        },
        handleFileChange(event) {
            if (this.selectedAbsence && this.selectedAbsence.healthConfirmationFileId !== null) {
                alert("Das Hochladen ist nicht erlaubt, da bereits eine Genesungsbestätigung vorliegt.");
                return;
            }
            this.file = event.target.files[0];
            this.handleUpload();
        },
        handleFileDrop(event) {
            if (this.selectedAbsence && this.selectedAbsence.healthConfirmationFileId !== null) {
                alert("Das Hochladen ist nicht erlaubt, da bereits eine Genesungsbestätigung vorliegt.");
                return;
            }
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                this.file = files[0];
                this.handleUpload();
            }
        },
        onDropZoneClick() {
            if (this.selectedAbsence && this.selectedAbsence.healthConfirmationFileId !== null) {
                alert("Das Hochladen ist nicht erlaubt, da bereits eine Genesungsbestätigung vorliegt.");
                return;
            }
            this.$refs.fileInput.click();
        },
        async handleUpload() {
            if (!this.file) {
                alert("Bitte wählen Sie eine Datei aus, bevor Sie versuchen, sie hochzuladen.");
                return;
            }

            const allowedTypes = [
                'image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp',
                'image/tiff', 'image/bmp', 'image/heic', 'image/heif', 'image/svg+xml',
                'image/x-icon', 'application/pdf'
            ];

            if (!allowedTypes.includes(this.file.type)) {
                alert('Ungültiger Dateityp. Bitte laden Sie nur Dateien der folgenden Formate hoch: JPEG, JPG, PNG, GIF, WEBP, TIFF, BMP, HEIC, HEIF, SVG, ICO, PDF.');
                return;
            }

            try {
                const formData = new FormData();
                formData.append('File', this.file); 

                const absenceId = this.selectedAbsence.id;
                const type = this.selectedDocumentType;

                if (!absenceId || !type) {
                    alert("Fehler: Abwesenheits-ID oder Dokumenttyp fehlt.");
                    return;
                }

                const uploadUrl = `/users/upload_documents/${absenceId}/${type}`;

                const response = await axios.post(uploadUrl, formData, {
                    withCredentials: true
                });

                if (response.data.success) {
                    alert('Dokument erfolgreich hochgeladen!');
                    this.closeUploadModal();
                    window.location.reload();
                } else {
                    alert('Fehler beim Hochladen: ' + response.data.message);
                }

            } catch (error) {
                console.error('Fehler beim Hochladen des Dokuments:', error);
                alert('Fehler beim Hochladen. Bitte versuchen Sie es erneut.');
            }
        }
    }
};
</script>

