<template>
    <nav class="bg-white shadow-md">
        <div class="container mx-auto px-4">
            <div class="flex justify-between items-center py-4">
                <!-- Logo/Brand -->
                <a class="text-2xl font-bold text-green-800" href="/dashboard">Social Supervisor</a>

                <!-- Hamburger Menu (Mobile) -->
                <button @click="toggleMenu" class="lg:hidden text-gray-800 focus:outline-none">
                    <svg class="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>

                <!-- Menu Items (Desktop) -->
                <div :class="{ 'block': isMenuOpen, 'hidden': !isMenuOpen }"
                    class="w-full lg:flex lg:items-center lg:w-auto">
                    <ul class="lg:flex lg:space-x-8 mt-4 lg:mt-0">
                        <li>
                            <a class="text-gray-800 hover:text-green-800 transition-colors duration-300 block px-3 py-2 rounded-md"
                                href="/bookings">Bookings</a>
                        </li>
                        <li>
                            <a class="text-gray-800 hover:text-green-800 transition-colors duration-300 block px-3 py-2 rounded-md"
                                href="/absences">Abwesenheiten</a>
                        </li>
                        <li>
                            <a @click="logoutAction"
                                class="text-gray-800 hover:text-green-800 transition-colors duration-300 block px-3 py-2 rounded-md"
                                href="#">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>



<script>
export default {
    name: 'NavBarComponent',
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        logoutAction() {
            this.$emit('logout');
        },
    },
};
</script>
