<template>
    <layout-div>
        <div class="flex justify-center mt-20">
            <div class="modal-container">
                <div class="modal-header">
                    <h5 class="text-xl font-bold">SPS Mitarbeiter Login</h5>
                </div>
                <div class="modal-body">
                    <div v-if="validationErrors.general" class="alert" role="alert">
                        {{ validationErrors.general }}
                    </div>
                    <form @submit.prevent="loginAction">
                        <div class="mb-4">
                            <label for="username" class="section-heading">
                                Benutzername
                            </label>
                            <input v-model="username" type="text" class="input-field" id="username" name="username" />
                        </div>
                        <div class="mb-6 position-relative">
                            <label for="password" class="section-heading">
                                Passwort
                            </label>
                            <input 
                                v-model="password" 
                                :type="passwordFieldType" 
                                class="input-field pe-5" 
                                id="password" 
                                name="password" 
                            />
                            <span 
                                class="position-absolute top-0 end-0 mt-2 me-3 cursor-pointer" 
                                @click="togglePasswordVisibility"
                            >
                                <i :class="passwordFieldType === 'password' ? 'bi bi-eye' : 'bi bi-eye-slash'" style="font-size: 1.2rem; color: black;"></i>
                            </span>
                        </div>
                        <div class="d-flex flex-column gap-4">
                            <button :disabled="isSubmitting" type="submit" class="button">
                                Anmelden
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';

export default {
    name: 'LoginPage',
    components: {
        LayoutDiv,
    },
    data() {
        return {
            username: '',
            password: '',
            passwordFieldType: 'password',
            validationErrors: {},
            isSubmitting: false,
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        loginAction() {
            this.isSubmitting = true;
            const payload = {
                username: this.username,
                password: this.password,
            };

            axios.post('/users/authenticate', payload, { withCredentials: true })
                .then(response => {
                    if (response.data.id) {
                        localStorage.setItem('user_id', response.data.id);
                        this.$router.push('/dashboard');
                    } else {
                        this.validationErrors.general = 'Es gab ein Problem bei der Anmeldung. Bitte versuche es später erneut.';
                    }
                    this.isSubmitting = false;
                })
                .catch(error => {
                    console.error(error);
                    this.isSubmitting = false;
                    if (error.response && error.response.data.message) {
                        this.validationErrors.general = error.response.data.message;
                    } else {
                        this.validationErrors.general = 'Es ist ein unbekannter Fehler aufgetreten.';
                    }
                });
        }
    },
};
</script>

<style scoped>
.input-field {
    padding-right: 2.5rem;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
