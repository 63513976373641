<template>
    <layout-div>
        <!-- Navbar -->
        <div class="content-container">
            <NavBarComponent @logout="logoutAction" />
            <div class="greeting-text">
                <p>Hier siehst du deine Buchungen...</p>
            </div>
        </div>

        <!-- Buchungsübersicht -->
        <div class="content-container mt-8">
            <h4 class="section-heading">Buchungsübersicht nach Datum</h4>
            <div v-for="(bookings, date) in sortedGroupedData" :key="date" class="mb-6">
                <div @click="toggle(date)" class="date-header">
                    <h5 class="text-lg font-semibold">{{ formatBookingDate(date) }}</h5>
                    <p><b>Tagesarbeitsstunden: {{ calculateDailyHours(bookings) }}</b></p>
                    <span v-if="isOpen(date)" class="text-xl">&#9660;</span>
                    <span v-else class="text-xl">&#9658;</span>
                </div>
                <div v-show="isOpen(date)" class="table-responsive">
                    <table class="table">
                        <thead class="bg-gray-50">
                            <tr>
                                <th class="table-cell">Kind-Name</th>
                                <th class="table-cell">Startzeit</th>
                                <th class="table-cell">Endzeit</th>
                                <th class="table-cell">Dienstzeit</th>
                                <th class="table-cell">Gel.Std</th>
                                <th class="table-cell">Stundenabweichung</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in bookings" :key="item.id">
                                <td class="table-cell">{{ extractChildName(item.children_name) }}</td>
                                <td class="table-cell">{{ item.start_datetime }}</td>
                                <td class="table-cell">{{ item.end_datetime }}</td>
                                <td class="table-cell">{{ item.working_hours }}</td>
                                <td class="table-cell">{{ item.total_working_hours }}</td>
                                <td class="table-cell"><b>{{ item.booking_diff }}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mt-8">
                <h4 class="section-heading">Gesamtarbeitsstunden pro Monat</h4>
                <div v-for="(hours, month) in monthlyWorkingHours" :key="month">
                    <p><b>Gesamtarbeitsstunden {{ month }}: {{ hours }}</b></p>
                </div>
            </div><br><br>
        </div>
    </layout-div>
</template>



<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NavBarComponent from '../NavBarComponent.vue';

export default {
    name: 'DashboardPage',
    components: {
        LayoutDiv,
        NavBarComponent
    },
    data() {
        return {
            user: {},
            combinedData: [],
            openDate: null,
            totalWorkingHours: 0,  // Variable für die Gesamtarbeitsstunden
        };
    },
    computed: {
        groupedData() {
            return this.combinedData.reduce((acc, item) => {
                const date = item.planned_date;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(item);
                return acc;
            }, {});
        },
        sortedGroupedData() {
            // Sortiere die Daten nach Datum in absteigender Reihenfolge (neueste zuerst)
            const dates = Object.keys(this.groupedData).sort((a, b) => {
                const dateA = new Date(a.slice(0, 4), a.slice(4, 6) - 1, a.slice(6, 8));
                const dateB = new Date(b.slice(0, 4), b.slice(4, 6) - 1, b.slice(6, 8));
                return dateB - dateA; // Absteigend sortieren (neueste zuerst)
            });

            return dates.reduce((sortedAcc, date) => {
                sortedAcc[date] = this.groupedData[date];
                return sortedAcc;
            }, {});
        },
        monthlyWorkingHours() {
            return this.combinedData.reduce((acc, item) => {
                const month = item.planned_date.slice(0, 6); // Extrahiere das Jahr und den Monat
                if (!acc[month]) {
                    acc[month] = 0;
                }
                acc[month] += parseFloat(item.total_working_hours || 0);
                return acc;
            }, {});
        }
    },
    created() {
        this.getUser();
    },
    methods: {
        async getUser() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                const userId = response.data.id;

                // API-Anfrage für alle Buchungen
                const userDataResponse = await axios.get(`/users/get_user_booking_data/${userId}`, { withCredentials: true });

                if (userDataResponse && userDataResponse.data) {
                    this.user = userDataResponse.data.user || {};
                    this.combinedData = userDataResponse.data.combinedData || [];

                    // Berechne die Gesamtarbeitsstunden
                    this.totalWorkingHours = this.combinedData.reduce((total, item) => total + parseFloat(item.total_working_hours || 0), 0);

                    // Initialisiere das neueste Datum als geöffnet
                    const dates = Object.keys(this.groupedData).sort((a, b) => {
                        const dateA = new Date(a.slice(0, 4), a.slice(4, 6) - 1, a.slice(6, 8));
                        const dateB = new Date(b.slice(0, 4), b.slice(4, 6) - 1, b.slice(6, 8));
                        return dateB - dateA; // Absteigend sortieren
                    });

                    if (dates.length > 0) {
                        this.openDate = dates[0]; // Das neueste Datum öffnen
                    }
                } else {
                    console.error('Unexpected response format:', userDataResponse);
                }

            } catch (error) {
                console.error('Error fetching combined data:', error);
                this.$router.push('/');
            }
        },
        formatBookingDate(dateStr) {
            if (!dateStr) return '';
            const date = new Date(dateStr.slice(0, 4), dateStr.slice(4, 6) - 1, dateStr.slice(6, 8));
            if (isNaN(date.getTime())) return '';
            return date.getFullYear() + '-' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + date.getDate()).slice(-2);
        },
        extractChildName(fullString) {
            const parts = fullString.split('_');
            return parts.slice(0, 2).join('_');
        },
        toggle(date) {
            this.openDate = this.openDate === date ? null : date;
        },
        isOpen(date) {
            return this.openDate === date;
        },
        calculateDailyHours(bookings) {
            return bookings.reduce((total, item) => total + parseFloat(item.total_working_hours || 0), 0).toFixed(2);
        },
        logoutAction() {
            localStorage.clear();
            axios.post('/users/logout', {}, { withCredentials: true })
                .then(() => {
                    this.clearCookies();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        },
        clearCookies() {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        },
    },
};



</script>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */

/* Container and Layout */
.content-container {
    @apply text-left mt-8 px-4 md:px-10;
}

.greeting-text {
    @apply text-center text-2xl font-bold mt-4;
    color: #1e8c4a;
    /* Helleres Grün für den Text */
}

.section-heading {
    @apply text-xl font-semibold text-gray-800;
    margin-bottom: 12px;
    /* Abstand nach unten für Klarheit */
}

/* Date Header */
.date-header {
    @apply bg-gray-100 p-4 rounded-lg cursor-pointer flex justify-between items-center;
}

/* Table Styling */
.table-responsive {
    @apply mt-4;
}

.table {
    @apply w-full bg-white border border-gray-300 rounded-lg;
}

.table-cell {
    @apply px-4 py-2 border text-gray-700;
}

th {
    @apply bg-gray-50 font-medium text-gray-700;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .content-container {
        @apply px-4;
    }

    .greeting-text {
        @apply text-xl;
        /* Verkleinere den Begrüßungstext */
    }

    .section-heading {
        @apply text-lg;
        /* Verkleinere die Überschriften */
    }

    .date-header {
        @apply p-3 text-lg;
        /* Verkleinere das Datum im Header */
    }

    .table-responsive {
        @apply mt-3;
        /* Reduziere den oberen Abstand der Tabelle */
    }

    .table-cell {
        @apply text-sm;
        /* Verkleinere den Text in der Tabelle */
    }

    th {
        @apply text-sm;
        /* Verkleinere den Text in den Tabellenköpfen */
    }
}

@media (max-width: 480px) {
    .greeting-text {
        @apply text-lg;
        /* Noch kleinerer Text für sehr kleine Geräte */
    }

    .section-heading {
        @apply text-base;
        /* Verkleinere die Überschriften weiter */
    }

    .date-header {
        @apply p-2 text-base;
        /* Verkleinere das Datum im Header noch mehr */
    }

    .table-cell {
        @apply text-xs;
        /* Verkleinere den Text in der Tabelle weiter */
    }

    th {
        @apply text-xs;
        /* Verkleinere den Text in den Tabellenköpfen weiter */
    }
}
</style>
