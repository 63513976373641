<template>
    <layout-div>

        <div v-if="isModalVisible" class="modal-overlay">
            <div class="modal-container">
                <div class="modal-header">
                    <h5 class="text-lg font-bold">Stundenbuchen für <b>{{ modalData.child_name }}</b></h5>
                    <button type="button" class="text-gray-600 hover:text-gray-900" @click="handleModalClose">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">
                        <label for="plannedDate" class="block text-sm font-medium text-gray-700">Geplantes Datum:</label>
                        <input type="text" id="plannedDate" v-model="modalData.planned_date" disabled class="input-field" />
                    </div>
                    <div class="mb-4">
                        <label for="startTime" class="block text-sm font-medium text-gray-700">Startzeit:</label>
                        <input type="time" id="startTime" v-model="modalData.start_time" @change="formatTime('start_time')" class="input-field" />
                    </div>
                    <div class="mb-4">
                        <label for="endTime" class="block text-sm font-medium text-gray-700">Endzeit:</label>
                        <input type="time" id="endTime" v-model="modalData.end_time" @change="formatTime('end_time')" class="input-field" />
                    </div>
                    <div class="mb-4">
                        <label for="bookingDiffComment" class="block text-sm font-medium text-gray-700">Kommentar zur Abweichung:</label>
                        <textarea id="bookingDiffComment" v-model="modalData.booking_diff_comment" rows="3" @input="sanitizeComment" placeholder="Kommentar zur Abweichung (mind. 5 Zeichen)" class="input-field"></textarea>
                        <small class="text-gray-500">Keine Sonderzeichen erlaubt.</small>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="button" @click="submitBooking" :disabled="!isCommentValid">Abschicken</button>
                </div>
            </div>
        </div>

        <div class="content-container">
            <NavBarComponent @logout="logoutAction" />
            <div class="greeting-text">
                <p>Guten Tag,<br>{{ user.firstName }}, {{ user.lastName }}</p>
            </div>
        </div>

        <div class="content-container">
            <h4 class="section-heading">Dein Kalender <i class="bi bi-calendar3"></i></h4>
            <div v-for="day in weekDays" :key="day.date" class="mb-6">
                <div :class="isToday(day.date) ? 'day-label-today' : 'day-label'">
                    {{ formatToLocaleDate(day.date) }}
                </div>
                <div class="calendar-container">
                    <div v-for="event in day.events" :key="event.id" :class="['event-card', isToday(day.date) ? 'event-card-today' : '']">
                        <div :class="['event-title', isToday(day.date) ? 'event-title-today' : '']">
                            {{ event.title }}
                        </div>
                        <div v-html="event.message" class="event-message"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-center mt-4">
            <button @click="goToPreviousWeek" class="button-secondary mx-2">
                <i class="bi bi-arrow-left"></i>
            </button>
            <button @click="goToNextWeek" class="button-secondary mx-2">
                <i class="bi bi-arrow-right"></i>
            </button>
        </div>

        <div class="content-container">
            <h4 class="section-heading">Heutige Assistenz {{ prettifyPlannedDate(formatDate2PlannedDate(this.selectedDate)) }}</h4>
            <div class="table-container">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="table-header">ID</th>
                            <th class="table-header">Kind-Name</th>
                            <th class="table-header">Startzeit</th>
                            <th class="table-header">Endzeit</th>
                            <th class="table-header">Fachbereich</th>
                            <th class="table-header">Buchungsdatum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in combinedData" :key="item.id">
                            <td class="table-cell">{{ item.fk_children_id }}</td>
                            <td class="table-cell">{{ extractChildName(item.children_name) }}</td>
                            <td class="table-cell">{{ item.start_datetime }}</td>
                            <td class="table-cell">{{ item.end_datetime }}</td>
                            <td class="table-cell">
                                <span :class="['px-2 py-1 rounded-full text-xs font-semibold','bg-green-100 text-green-800']">
                                    {{ item.working_area }}
                                </span>
                            </td>
                            <td class="table-cell">{{ formatBookingDate(item.booking_date) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="combinedData.length > 0 && checkAllBookingsFinished()" class="mt-4">
                <p>Gel.Std: <b>{{ this.bookingSummary.overall_working_hours || 0 }} Stunden</b></p>
                <p>Saldo zu Anmelde.Std. Basis {{ this.bookingSummary.registered_working_hours_per_day || 0 }} : <b>{{ this.bookingSummary.registered_working_hours_per_day_diff || 0 }} Stunden</b></p>
                <p>Saldo zu Service.Std. Basis {{ this.bookingSummary.service_working_hours_per_day || 0 }} : <b>{{ this.bookingSummary.service_working_hours_per_day_diff || 0 }} Stunden</b></p>
            </div>

            <button v-if="combinedData.length > 0 && !checkAllBookingsFinished() && !lockedStatus" @click="finishBookingForDay" :disabled="checkAllBookingsFinished()" class="button mt-4">
                Tag abschließen <i class="bi bi-check-circle"></i>
            </button>

        </div>
        <div v-if="lockedStatus" class="dynamic-text mt-2 alert-success">
            <br><p>{{ dynamicText }}</p>
        </div><br><br><br>

        <!-- <div class="content-container">
            <div v-if="showAlert" class="alert">
                {{ alertMessage }}
            </div>
            <div v-if="showSuccessAlert" class="alert-success">
                Nachricht wurde erfolgreich abgesendet.
            </div>
            <h4 class="section-heading">Nachricht an Regionalleitung</h4>
            <div class="mb-4">
                <input type="text" id="messageTitle" v-model="messageTitle" maxlength="150" placeholder="Titel eingeben"
                    required class="input-field" />
            </div>
            <div class="mb-4">
                <textarea id="messageContent" v-model="messageContent" rows="5" maxlength="500"
                    placeholder="Ihre Nachricht" required class="input-field"></textarea>
            </div>
            <button @click="sendMessage" class="button">
                Nachricht senden <i class="bi bi-send"></i>
            </button><br><br>
        </div> -->
    </layout-div>
</template>


<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NavBarComponent from '../NavBarComponent.vue';

export default {
    name: 'DashboardPage',
    components: {
        LayoutDiv,
        NavBarComponent
    },
    data() {
        return {
            lockedStatus: false,
            dynamicText: 'Stundenbuchen gesperrt.',
            user: {},
            userId: 0,
            combinedData: [],
            selectedDate: new Date(),
            weekDays: [],
            modalData: {
                planned_date: '',
                start_time: '07:00:00',
                end_time: '08:00:00',
                currentBookingId: null,
                child_name: '',
                booking_diff_comment: ''
            },
            messageTitle: '',
            messageContent: '',
            showSuccessAlert: false,
            showAlert: false,
            alertMessage: '',
            isModalVisible: false,
            bookingSummary: {},
        };
    },
    computed: {
        isCommentValid() {
            return this.modalData.booking_diff_comment.length >= 5;
        }
    },
    created() {
        this.initializeDashboard();
        this.checkLockedStatus();
    },
    methods: {
        async checkLockedStatus() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;
                const today = new Date();
                const formattedDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);
                const userDataResponse = await axios.get(`/users/get_user_data/${this.userId}/${formattedDate}`, { withCredentials: true });
                if (userDataResponse && userDataResponse.data) {
                    this.lockedStatus = userDataResponse.data.locked_status;
                    this.startProgressAnimation();
                }
            } catch (error) {
                console.error('Error fetching locked status:', error);
            }
        },
        startProgressAnimation() {
            if (this.lockedStatus) {
                let dots = 0;
                this.intervalId = setInterval(() => {
                    dots = (dots + 1) % 4;
                    this.dynamicText = 'Stundenbuchen gesperrt' + '.'.repeat(dots);
                }, 500);
            }
        },
        stopProgressAnimation() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        prettifyPlannedDate(plannedDate) {
            if (!plannedDate || plannedDate.length !== 8) return '';
            const year = plannedDate.substring(0, 4);
            const month = plannedDate.substring(4, 6).replace(/^0+/, '');
            const day = plannedDate.substring(6, 8).replace(/^0+/, '');
            return `${day}.${month}.${year}`;
        },
        openBookingModal(item, plannedDate) {
            this.modalData.planned_date = plannedDate;
            this.modalData.start_time = item.start_datetime;
            this.modalData.end_time = item.end_datetime;
            this.modalData.currentBookingId = item.id;
            this.modalData.child_name = this.extractChildName(item.children_name);
            this.modalData.booking_diff_comment = '';
        },
        async submitBookingNoDivergence() {
            const bookingDiffComment = {
                start_time: this.modalData.start_time,
                end_time: this.modalData.end_time,
                comment: this.modalData.booking_diff_comment
            };
            const postData = {
                start_time: this.modalData.start_time,
                end_time: this.modalData.end_time,
                booking_diff_comment: JSON.stringify(bookingDiffComment)
            };
            try {
                await axios.post(`/users/booking_divergence/${this.modalData.currentBookingId}`, postData, { withCredentials: true });
            } catch (error) {
                console.error('Fehler beim Senden der Buchungsabweichung:', error);
            }
            this.isModalVisible = false;
        },
        async submitBooking() {
            if (!this.isCommentValid) {
                alert("Der Kommentar muss mindestens 5 Zeichen lang sein.");
                return;
            }
            const bookingDiffComment = {
                start_time: this.modalData.start_time,
                end_time: this.modalData.end_time,
                comment: this.modalData.booking_diff_comment
            };
            const postData = {
                start_time: this.modalData.start_time,
                end_time: this.modalData.end_time,
                booking_diff_comment: JSON.stringify(bookingDiffComment)
            };
            try {
                await axios.post(`/users/booking_divergence/${this.modalData.currentBookingId}`, postData, { withCredentials: true });
            } catch (error) {
                console.error('Fehler beim Senden der Buchungsabweichung:', error);
            }
            this.isModalVisible = false;
        },
        async finishDay() {
            try {
                const planned_date = this.formatDate2PlannedDate(this.selectedDate);
                const postData = { planned_date };
                await axios.post(`/users/booking_finish/${this.user.id}`, postData, { withCredentials: true });
                this.refreshData();
                this.showSuccessAlert = true;
                this.alertMessage = 'Der Arbeitstag wurde erfolgreich abgeschlossen.';
            } catch (error) {
                this.showAlert = true;
                this.alertMessage = 'Es gab einen Fehler beim Abschließen des Arbeitstags.';
            } finally {
                window.location.reload();
            }
        },
        async handleNoDivergence() {
            await this.submitBookingNoDivergence();
            await this.finishDay();
            this.closeModal();
        },
        async handleModalClose() {
            await this.submitBookingNoDivergence();
            await this.finishDay();
            this.closeModal();
        },
        closeModal() {
            this.isModalVisible = false;
        },
        async initializeDashboard() {
            try {
                await this.getUser();
                await this.initializeWeek();
                await this.totalWorkingHours();
            } catch (error) {
                console.error("Error during initialization:", error);
            }
        },
        sanitizeComment() {
            this.modalData.booking_diff_comment = this.modalData.booking_diff_comment.replace(/[^a-zA-Z0-9äöüÄÖÜß\s]/g, '');
        },
        formatTime(field) {
            const timeValue = this.modalData[field];
            if (timeValue && timeValue.length === 5) {
                this.modalData[field] = `${timeValue}:00`;
            }
        },
        isWeekend() {
            const today = new Date();
            const dayOfWeek = today.getDay();
            return dayOfWeek === 0 || dayOfWeek === 6;
        },
        isToday(date) {
            const today = new Date();
            return this.formatDate(date) === this.formatDate(today);
        },
        formatToLocaleDate(date) {
            const d = new Date(date);
            return d.toLocaleDateString('de-DE', { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' });
        },
        formatBookingDate(dateStr) {
            if (!dateStr) return '';
            const date = new Date(dateStr);
            if (isNaN(date.getTime())) return '';
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
        },
        logoutAction() {
            localStorage.clear();
            axios.post('/users/logout', {}, { withCredentials: true })
                .then(() => {
                    this.clearCookies();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        },
        clearCookies() {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        },
        extractChildName(fullString) {
            const parts = fullString.split('_');
            return parts.slice(0, 2).join('_');
        },
        formatDate2PlannedDate(date) {
            if (!(date instanceof Date)) date = new Date(date);
            const year = date.getFullYear().toString();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            return year + month + day;
        },
        necessaryWorkingHours() {
            return this.combinedData.reduce((sum, item) => sum + parseFloat(item.working_hours), 0);
        },
        async totalWorkingHours() {
            const planned_date = this.formatDate2PlannedDate(this.selectedDate);
            try {
                const response = await axios.get(`/users/get_total_booking_hours/${this.userId}/${planned_date}`, { withCredentials: true });
                this.bookingSummary = response.data?.bookingSummary || 0;
            } catch (error) {
                console.error('Error fetching total working hours:', error);
            }
        },
        async finishBookingForDay() {
            if (confirm("Info: Mit Klick auf OK wird der heutige Arbeitstag eingebucht und kann nicht mehr geändert werden.")) {
                const plannedDate = this.formatDate2PlannedDate(this.selectedDate);
                for (const item of this.combinedData) {
                    this.openBookingModal(item, plannedDate);
                    this.isModalVisible = true;
                    await new Promise(resolve => {
                        this.$watch('isModalVisible', newValue => {
                            if (!newValue) resolve();
                        });
                    });
                }
                await this.finishDay();
            }
        },
        checkAllBookingsFinished() {
            return this.combinedData.every(item => item.booking_finished);
        },
        async refreshData() {
            await this.getUser();
        },
        async getUser() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;
                const today = new Date();
                const formattedDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);
                const userDataResponse = await axios.get(`/users/get_user_data/${this.userId}/${formattedDate}`, { withCredentials: true });
                if (userDataResponse && userDataResponse.data) {
                    this.user = userDataResponse.data.user || {};
                    this.combinedData = userDataResponse.data.combinedData || [];
                } else {
                    console.error('Unexpected response format:', userDataResponse);
                }
            } catch (error) {
                console.error('Error fetching combined data:', error);
                this.$router.push('/');
            }
        },
        goToPreviousWeek() {
            this.changeWeek(-1);
        },
        goToNextWeek() {
            this.changeWeek(1);
        },
        changeWeek(weeksToAdd) {
            this.selectedDate.setDate(this.selectedDate.getDate() + weeksToAdd * 7);
            this.initializeWeek();
        },
        async initializeWeek() {
            const startOfWeek = new Date(this.selectedDate);
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + (startOfWeek.getDay() === 0 ? -6 : 1));
            this.weekDays = [];
            for (let i = 0; 7 > i; i++) {
                const day = new Date(startOfWeek);
                day.setDate(day.getDate() + i);
                const events = await this.getEventsForDate(day);
                this.weekDays.push({ date: day, events });
            }
        },
        async getEventsForDate(date) {
            try {
                const formattedDate = this.formatDate(date);
                const response = await axios.get('/users/current', { withCredentials: true });
                const userId = response.data.id;
                const eventsResponse = await axios.get(`/users/get_calendar_data/${userId}`, { withCredentials: true });
                const eventsForDate = eventsResponse.data.filter(event => event.date === formattedDate);
                return eventsForDate.map((event, idCounter = 1) => ({
                    id: idCounter++,
                    title: event.title,
                    date: event.date,
                    message: event.message.replace(/;/g, '<br>'),
                }));
            } catch (error) {
                console.error('Error fetching events:', error);
                return [];
            }
        },
        formatDate(date) {
            if (!(date instanceof Date)) date = new Date(date);
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        },
    }
};
</script>

<style>
.dynamic-text {
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
    text-align: center;
}
</style>
